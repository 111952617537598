/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

/* black and Goldenen theme */
:root {
  /* Main theme color */
  --portal-theme-primary: #f6bd4b;
  --portal-theme-primary-filter-color: invert(74%) sepia(96%) saturate(396%)
    hue-rotate(332deg) brightness(101%) contrast(93%);
  --portal-theme-secondary: rgba(175, 151, 102, 0.199);
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  /* Main background colors */
  --background-primary-color: black;
  --background-secondary-color: black;
  /* Sidebar Properties */
  --sidebars-text-color: white;
  --sidebars-active-color: #f6bd4b;
  --sidebars-hover-color: #f6bd4b;
  --sidebars-background-color: #1d1c1d;
  /* Popup / Popover properties  */
  --popup-background-color: black;
  --popup-text-color: white;
  /* Element properties */
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  --icons-color-allover: #f6bd4b;
  /* Button Properties */
  --button-background-color: #1c1c1c;
  --button-text-color: #f6bd4b;
  --button-outlined-color: #f6bd4b;
  --button-hover-color: "#f6bd4b";
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --datepicker-selected-color: #fff;
}

/*  */
/*  */
/* //////////////////////////////////////////////customize scrollbar */
/*  */
/*  */

/* ------------------------- */

/*  in chrome this works */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
/* HINA KHAN IN DARK MODE CONVERSION STARTS FROM HERE */
body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.secondary-background {
  background-color: var(--background-secondary-color);
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  color: var(--text-primary-color);
}

.css-2tnm32.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}

::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

.css-18dh5wg-MuiTypography-root {
  color: var(--text-primary-color) !important;
}

.css-6obpvq-MuiTypography-root-MuiLink-root {
  color: var(--text-primary-color);
}

/* calender colors */
.MuiCalendarPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}

.MuiCalendarPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}

.MuiCalendarPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--portal-theme-primary-filter-color);
  cursor: pointer;
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  display: none !important;
}

.MuiPickersToolbar-root .css-xzqsgo {
  display: none !important;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  color: #ccc5c5;
}

/* calender colors end */

/* card background */
/* .css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
} */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-ju84cc-MuiButtonBase-root-MuiMenuItem-root,
.css-1h0kuck-MuiButtonBase-root-MuiButton-root {
  color: var(--text-primary-color);
}

.css-1fuveia-MuiPaper-root-MuiPopover-paper,
.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

/* .css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  color: var(--sidebar-normal-color);
} */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-primary-color);
}

.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

/* fieldset {
  border-color: var(--portal-theme-secondary) !important;
}
fieldset:focus {
  border-color: var(--portal-theme-primary) !important;
} */
.svg-color svg,
.MuiCalendarPicker-root svg {
  color: var(--portal-theme-primary);
}

/* calendar selected Color */
.css-14ewge7.Mui-selected,
.css-14ewge7.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

.css-1qwrva7-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}

/* calender selected hover */
.css-2tnm32.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

/* .css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--portal-theme-primary);
} */
.css-1w4063c-MuiTypography-root,
.css-rq00hd-MuiTypography-root {
  cursor: pointer;
}

.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  /* background-color: var(--portal-theme-primary); */

  color: var(--background-primary-color);
}

.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}

.css-1gxhmaf-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}

.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary) !important;
}

.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}

.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}

.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none;
}

.css-1nuss9t {
  color: var(--text-primary-color);
}

.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-lvwv31 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}

.css-pt151d {
  color: var(--portal-theme-primary);
}

.css-yzm7vx,
.css-184p1yy,
.css-12ff9sa,
.css-12ff9sa {
  color: var(--text-primary-color);
}

.review-card {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-k7695x-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
  /* z-index: 11111 !important; */
}

.css-3ioe56 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-1hes0mo:hover {
  color: var(--portal-theme-primary);
}

.css-66zhem {
  background-color: var(--background-primary-color);
}

.css-ze1xs7 {
  color: var(--portal-theme-primary) !important;
}

.css-f0u5qy {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}

.css-12ff9sa {
  color: none;
}

.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}

.css-r13mc8 {
  color: var(--portal-theme-primary);
}

.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}

.css-yxnldu {
  background-color: var(--sidebars-background-color);
}

/* .css-i4bv87-MuiSvgIcon-root {
  color: var(--button-text-color);
} */
.css-1bfvxec {
  color: var(--text-primary-color);
}

.css-yzm7vx {
  color: var(--text-primary-color);
}

.css-vb81xk-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-primary-color);
}

.css-zuwxiq {
  color: var(--text-primary-color);
}

.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}

.css-13d5z5v {
  color: var(--background-primary-color);
  /* background-image: url(../images/btn-img.png); */
}

.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
}

.css-q6wzhr {
  color: var(--portal-theme-primary);
}

.css-zuwxiq {
  color: var(--text-primary-color);
}

.css-2300kw {
  color: var(--text-primary-color);
}

.program-lock-icon .css-vubbuv {
  fill: var(--portal-theme-primary);
}

.css-vubbuv {
  fill: var(--portal-theme-primary);
}

.dashboard-welcome-description h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.css-4cxmk4,
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.card {
  background-color: var(--background-secondary-color);
}

.modal-theme {
  background-color: var(--sidebars-background-color);
}

.css-1xjb83h {
  color: var(--text-primary-color);
}

.css-rmquh4-MuiButtonBase-root-MuiChip-root {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
}

.css-1tir071-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}

.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}

.css-muvvuh {
  color: var(--text-primary-color);
}

.transaction-screen {
  background-color: var(--background-primary-color);
}

.css-2300kw.Mui-disabled {
  color: var(--text-primary-color);
}

.css-1tc40pv {
  color: var(--input-text-color);
}

/* .css-ijridm-MuiTypography-root {
  color: var(--portal-theme-primary);
} */
.css-3ioe56.Mui-expanded:last-of-type {
  background-color: var(--sidebars-background-color);
}

/* HINA KHAN IN DARK MODE CONVERSION ENDS HERE */

h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}

h2 {
  font-size: 30px;
  color: var(--portal-theme-primary);
}

h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

.normal-text {
  font-size: 14px;
  font-weight: 500;
}

.text-secondary {
  color: var(--text-secondary-color) !important;
}

a:hover {
  text-decoration: none !important;
  color: var(--sidebars-hover-color);
}

legend {
  float: unset;
}

.circular-progress {
  margin-left: 50%;
  margin-top: 20%;
}

.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
}

.inputs-fields {
  background-color: var(--input-background-primary);
  /* background-color: green; */
}

.form-control:focus {
  background-color: var(--textarea-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}

.form-control {
  border: none;
  color: var(--text-primary-color);
}

.programm-card {
  background-color: var(--background-secondary-color);
}

.programm-card h3 {
  color: var(--portal-theme-primary);
}

.programm-card p span {
  color: var(--text-secondary-color);
}

/* portal-wrapper */
.portal-wrapper {
  padding: 30px 0;
}

.portal-wrapper h2,
.services-testimonial-wrapper h2 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px !important;
  color: #fff !important;
  line-height: 1.1;
}

.portal-member h2 {
  line-height: 1.3;
}

.portal-card {
  background: linear-gradient(113.76deg, rgba(0, 0, 0, 0) 0%, #151a21 100%);
  border: 1px solid rgba(181, 157, 78, 0.5);
  padding: 20px 20px 25px;
  border-radius: 6px;
}

.bg-card-black {
  background: #1e2020;
  border-radius: 12px;
  padding: 25px 20px;
}

.bg-card-yellow {
  background: #b59d4e;
  border-radius: 12px;
  padding: 25px 20px;
  color: #151717;
}

.bg-card-black h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.bg-card-black h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #ccc9c4;
}

.bg-card-black h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-black h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #b59d4e,
    rgba(0, 0, 0, 0)
  );
}

.bg-card-yellow h5 {
  color: #151717;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.bg-card-yellow h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #151717;
}

.bg-card-yellow h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-yellow h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #000000,
    rgba(0, 0, 0, 0)
  );
}

.faq-portal-list {
  margin-bottom: 0px;
  padding-left: 10px;
}

.faq-portal-list li {
  display: flex;
  align-items: center;
}

.faq-portal-list li > span {
  padding-left: 12px;
  font-size: 12px;
  color: #ccc9c4;
}

.faq-portal-list li > i {
  color: #bc9c22;
}

.faq-portal-list li > span,
.faq-portal-list li > i {
  padding-top: 14px;
}

.btn-portal button {
  background: #b59d4e;
  color: #151717;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 30px;
  position: absolute;
  bottom: -25px;
}

.btn-btn-portal {
  padding: 14px 35px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  transition: 0.5s;
  background: #b59d4e;
  color: #000000;
  border: 1px solid #b59d4e;
}

.btn-btn-portal:hover {
  color: #b59d4e;
  border: 1px solid #515151;
  background: transparent;
}

@media (max-width: 525px) {
  .portal-card {
    padding: 20px 20px 35px;
  }
}

/* portal-banner */
.portal-banner {
  padding: 60px 0 80px;
}

.portal-card-linear {
  background: linear-gradient(
      89.74deg,
      #151717 0.23%,
      rgba(21, 23, 23, 0) 75.85%
    ),
    radial-gradient(
      75.75% 168.25% at 100% 84.87%,
      rgba(181, 157, 78, 0.2) 0%,
      rgba(21, 23, 23, 0) 100%
    );
  border-radius: 0px 24.4131px 24.4131px 0px;
  padding: 40px 40px;
  height: auto;
  font-size: 16px;
}

.portal-card-linear h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.portal-card-linear p {
  color: #ccc9c4;
  font-weight: 400;
}

.video-portal-border {
  border-radius: 10px;
  border-width: 2px;
  padding: 2px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(181, 157, 78, 0.5)
  );
}

.video-portal-border iframe {
  border-radius: 10px;
  width: 100%;
  background: #000000;
}

/* =========== services-testimonial-wrapper ============== */
.services-testimonial-wrapper {
  padding: 100px 0 0px;
  position: relative;
}

.center-slider .slick-current.slick-active .content .arrow {
  font-size: 30px;
}

.arrowStyle {
  position: relative;
  width: 45px;
  height: 45px;
  color: #bc9c22;
  border: 1px solid #b59d4e;
  line-height: 40px;
  font-size: 18px;
  margin-left: 15px;
  border-radius: 50px;
  background-color: transparent;
  transition: all 500ms ease;
  text-align: center;
}

.arrowStyle:hover {
  color: #fff;
  background-color: #b59d4e;
}

.owl-nav {
  position: absolute;
  right: 9%;
  top: 14px;
  text-align: right;
}

.style {
  font-size: 17px !important;
}

.inner-div-home {
  background: linear-gradient(113.76deg, rgba(0, 0, 0, 0) 0%, #151a21 100%);
  padding: 50px 45px;
  border: 1.19468px solid rgba(181, 157, 78, 0.5);
  opacity: 0.9;
  border-radius: 8px;
  margin: 20px 12px;
  position: relative;
}

.inner-div-home::before {
  content: "";
  position: absolute;
  top: 14.4rem;
  left: 2rem;
  width: 0;
  height: 0;
  border-top: 0.825rem solid transparent;
  border-right: 0.925rem solid #b59d4e;
  border-bottom: 0.825rem solid transparent;
  z-index: 1;
  transform: rotate(270deg);
}

.inner-div-home::after {
  content: "";
  position: absolute;
  top: 14.4rem;
  left: 2rem;
  width: 0;
  height: 0;
  border-top: 0.825rem solid transparent;
  border-right: 0.925rem solid #151717;
  border-bottom: 0.825rem solid transparent;
  z-index: 2;
  transform: rotate(270deg);
}

.slider-home-img {
  position: absolute;
  top: -19px;
  left: 30px;
  z-index: 10;
}

.card-img-home {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.card-img-para h6 {
  font-weight: 600;
  color: #fff;
}

.card-img-para p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ccc9c4;
}

.home-slider-block p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 0;
  color: #ccc9c4;
}
.answer-ticket {
  background-color: #007bff;
  color: white;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white;
  width: 80px;
}

.pending-ticket {
  background-color: #ffc107d9;
  color: white;
  width: 80px;
}
.home-slider-block p:before {
  content: "";
  width: 20%;
  height: 1px;
  position: absolute;
  left: -14px;
  transform: rotate(90deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #b59d4e,
    rgba(0, 0, 0, 0)
  );
}

@media only screen and (max-width: 768px) {
  .owl-nav {
    position: absolute;
    right: 6%;
    top: 0px;
    text-align: right;
  }

  .inner-div-home {
    padding: 50px 10px 50px 30px;
  }

  .inner-div-home::before,
  .inner-div-home::after {
    position: absolute;
    top: 16.6rem;
  }

  .home-slider-block p:before {
    left: -7px;
  }
}

@media only screen and (max-width: 540px) {
  .owl-nav {
    position: absolute;
    right: 6%;
    top: 0px;
    text-align: right;
  }
}

@media (max-width: 414px) {
  .owl-nav {
    position: absolute;
    right: 1%;
    top: 0px;
    text-align: right;
  }

  .arrowStyle {
    width: 35px;
    height: 35px;
    line-height: 33px;
  }

  .style {
    font-size: 14px !important;
  }
}

/* End portal banner */

/* MUI Card */
.mui-custom-card {
  background-color: var(--background-secondary-color);
}

.mui-custom-card h3 {
  color: var(--portal-theme-primary);
}

.mui-custom-card p span {
  color: var(--text-secondary-color);
}

.mui-custom-card-content {
  padding: 15px 15px 20px 15px;
}

.mui-custom-card-description {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.mui-card-button {
  fill: var(--portal-theme-primary);
  top: 45%;
  left: 22%;
  font-size: 14px;
  position: absolute;
  padding: "10px 20px";
}
/* .mui-card-button-program {
  left: 40% !important;
} */

.wider-card .mui-card-button {
  left: 45%;
}

.mui_gray_description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.mui-custom-card-dots {
  z-index: 10 !important;
}

.mui-custom-card-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.card_lower_title_description {
  margin-top: 16px !important;
  margin-bottom: 0 !important;
  color: #fff;
}

.side-image-mui-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.side-image-mui-card h4 {
  color: var(--portal-theme-primary);
}

/* MUI Card Ended*/

.lesson-card h4 {
  color: var(--portal-theme-primary);
}

.textarea-color {
  background-color: var(--textarea-color);
}

.textarea-block textarea {
  color: var(--text-primary-color);
}

.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.custom-popover-icon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: var(--portal-theme-primary) !important;
}

.custom-popover li {
  z-index: 10;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.diray-title h4 {
  font-weight: 400 !important;
}

.dynamite-diary .heading {
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  color: white;
}

.MuiAppBar-root {
  background-color: black;
}

.diary-first-heading {
  color: #ccc9c4;
}

.coint-img {
  width: 8%;
  height: auto;
}
.coint-image img {
  width: 40px;
  height: auto;
}

.css-epd502 {
  background-color: black;
}

.calender .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.5s;
}

/* .calender .MuiSvgIcon-root:hover{
  background-color: var(--button-text-color);
  color: black !important;
} */
.calender .MuiPickersCalendarHeader-label {
  font-weight: 500;
}

.coint-detail {
  align-items: center;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 9px;
}

.calender {
  justify-content: center;
  /* margin-top: -18px; */
}

.dynamite-diary .MuiDatePickerToolbar-root {
  display: none !important;
}

.cross-icon {
  padding-top: 1px;
  height: 20px;
  width: 20px;
  font-size: 10px;
  text-align: center;
  padding-top: 1px;
  border-radius: 50px;
  color: white;
  position: absolute;
  right: 1%;
  /* background-color: var(--portal-theme-primary); */
  top: 10px;
}
.cross-daily {
  right: 0% !important;
}
.intentionCheckox {
  margin-top: -10px;
}

.diary-icon-remove {
  color: red;
  fill: red;
  margin-bottom: 2px;
  cursor: pointer;
}

.diary-icon-add {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.fields-container {
  margin-bottom: 70px;
}

.dynamite-diary p {
  color: #ccc9c4;
  font-size: 12px;
}

.date-heading {
  color: #ccc9c4;
}

.diary-font-weight {
  font-weight: 400 !important;
  justify-content: space-between;
}

.diary-font-weight h4 {
  font-weight: 400 !important;
}

.set-manu-position {
  position: absolute;
  top: 0px;
  right: 10px;
}

.field-container {
  width: 92%;
}

.icon-container {
  width: 8%;
  border: 1px solid gray;
  height: 53px;
  margin-top: 9px;
  margin-left: 6px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: var(--button-outlined-color);
}

.calender .MuiDialogActions-spacing {
  display: none !important;
}

.subHeading {
  font-size: 20px;
  margin-top: 22px !important;

  margin-bottom: 20px !important;
  text-transform: capitalize;
}

.set-manu-position .MuiSvgIcon-root {
  transform: rotate3d(6, 4, -62, 91deg);
  border: transparent;
  color: white !important;
}

.mini-cards {
  position: relative;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.mini-cards h4 {
  font-weight: 400 !important;
}

.text-color {
  color: white !important;
}

.text-align {
  justify-content: flex-end;
  padding-top: 3px;
}

.gray_description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary-color);
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: var(--background-primary-color);
}

.small-contained-button {
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
}

.view-challenge-button {
  background: #b59d4e;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 20px;
  white-space: nowrap;
  padding: 8px 20px;
  border: none;
  outline: none;
}

.lesson-detail-page .mark-contained-button {
  background-color: var(--button-outlined-color);
  color: var(--input-text-color);
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
}

.small-outlined-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--background-primary-color);
  border-radius: 5px;
  border: 2px solid var(--portal-theme-primary);
}

.program-btn {
  text-align: left;
  margin-bottom: 20px;
}

.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

.lesson-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card {
  background-color: var(--background-secondary-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card .request-type {
  font-weight: 500;
}

.data-table {
  color: var(--text-primary-color);
  text-align: center;
}

.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}

.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid var(--portal-theme-secondary);
}

.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}

.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}

.loagin-button.Mui-disabled {
  background-color: var(--portal-theme-secondary) !important;
}

.loagin-button .MuiLoadingButton-loadingIndicator {
  color: var(--portal-theme-primary) !important;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.date-color {
  color: var(--text-secondary-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}

.back-screen-button {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  margin-bottom: 10px;
}

.new-chat-button {
  background-color: var(--portal-theme-primary);
  color: #fff;
  margin-bottom: 50px;
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
}
.new-chat-button svg {
  color: #fff;
  fill: #fff;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.memories-date p {
  color: var(--text-primary-color);
  margin-top: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--portal-theme-secondary) !important;
}

.carousel-nav-button {
  background-color: var(--text-primary-color) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}

.css-d82q1s-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
}

.submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-chat-upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 10px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-chat-upload-button label img {
  width: 15px;
  height: 15px;
}

.preview,
.chat-image-preview,
.add-event-comment-text-field {
  position: relative;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.chat-image-preview img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.preview span,
.chat-image-preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.event-chat-send-button {
  padding: 9px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.comment-cancel-update {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 20px;
  width: 20px;
}

.comment-cancel-update svg {
  width: 15px;
}

.event-chat-send-button img {
  width: 17px;
  height: 17px;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}

.preview span:hover {
  cursor: pointer;
}

.new-memories .MuiFormControl-root {
  width: 100%;
}

.event-details {
  margin: 20px auto;
}

.event-details h1 {
  font-size: 30px;
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}

#player {
  width: 100% !important;
}

.card-full-width {
  width: 100%;
}

.rating-stars-box {
  position: absolute;
  bottom: 0%;
}

.download-icon {
  margin-top: 12px;
}

.remove-border {
  border: none !important;
  margin: 0 !important;
  z-index: 10 !important;
}

.remove-border svg {
  z-index: 1 !important;
}

.sidebar-icon svg {
  color: var(--button-text-color) !important;
}

.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}

.set-image-center img {
  margin: auto;
}

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
} */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

.wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.range-slider span {
  color: var(--portal-theme-primary);
}

.css-1e799a4-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}

.notes-input {
  padding: 15px;
  padding-right: 30px;
}

.slider-box {
  position: relative;
}

.join-now-btn {
  position: absolute;
  bottom: 25px;
}

.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: var(--portal-theme-primary);
}

.event-slider .slick-prev,
.event-slider .slick-next {
  z-index: 1;
}

.programm-svg:hover {
  cursor: pointer;
}

.review-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
}

.review-slider .slick-slide {
  flex: 0 0 auto;
  height: auto !important;
}

.review-slider .slick-slide > div {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--button-outlined-color);
  height: 100%;
  border-radius: 5px;
}

.review-slider .card-border {
  border: none;
}

.dashboard-circle {
  height: 565px;
}

/* chat style Support Tickets*/
.chat-main-card {
  background-color: var(--sidebars-background-color);
  box-shadow: none;
  color: var(--text-primary-color);
}

.chat-main-card .card-title {
  color: var(--portal-theme-primary);
}

.chat-title-head {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}

.chat-message-head {
  border-bottom: 1px solid gray;
}

.chat-title-body {
  border-right: 1px solid gray;
}

.chat-message-body {
  /* background-color: rgba(158, 163, 161, 0.08) !important; */
  /* border-top: 1px solid #282727; */
  position: relative;
  width: 100%;
}

.modal-content {
  background-color: var(--sidebars-background-color);
}

.mui-btn-close {
  /* color: var(--button-outlined-color) !important; */
  text-align: right;
}

.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}

.chat-main-card hr {
  margin: 0;
}

.chat-side-bar {
  padding-right: 0 !important;
}

.chat-title-body:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
  cursor: pointer;
}

.uiDataGrid-sortIcon {
  display: none;
}

.open-ticket-color {
  background-color: #007bff;
  color: var(--input-text-color);
  border-color: #007bff;
  width: 90px;
}

.solved-ticket-color {
  background-color: #28a745;
  color: var(--input-text-color);
  border-color: #28a745;
  width: 90px;
}

.waiting-ticket-color {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
  width: 90px;
}

.css-qen1a1-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.dx-sort {
  display: none !important;
}

.suppor-ticket-data-table .MuiDataGrid-row {
  cursor: pointer;
}

.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}

.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}

.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}

.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
}

.custom-popover-box .remove-border {
  right: 5px;
  width: 30px;
  position: absolute;
  top: 40px;
}

.ticket_image_preview img:hover {
  cursor: pointer;
}

.SnackbarItem-action {
  display: inline;
  max-width: 80px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* for datepicker background on mobile */
.MuiDialog-paperScrollPaper {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}

/* end chat style Support Tickets*/

.associate-section {
  padding-left: 40px;
  color: var(--text-primary-color);
}

@media only screen and (max-width: 375px) {
  /* .custom-video-player {
    height: 100% !important;
  } */
  .lesson-heading {
    padding-top: 10px;
  }

  .side-image-mui-card-heading {
    padding-top: 10px;
  }

  .card-spacing {
    margin-top: 0.75rem;
  }

  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .preview img {
    height: auto;
  }

  .fc-button-primary {
    padding: 3px !important;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .fc-toolbar-title {
    font-size: 16px !important;
  }
}

/*=================================  full calender  =======================*/

.calender-events-box {
  margin-top: 10px;
}

.calender-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}

.fc-daygrid-day-frame {
  overflow: hidden;
}

.fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
}

.calender-events-text {
  color: white;
}

.full-calendar .fc-more-popover {
  background-color: var(--popup-background-color);
  z-index: 1;
}

.full-calendar .fc-more-popover .fc-popover-header {
  background: var(--fc-neutral-bg-color, hsla(0, 0%, 81.6%, 0.3));
  color: #fff;
}

.fc-toolbar-chunk {
  display: inline-flex;
}

.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: var(--portal-theme-primary);
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--text-primary-color);
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}

.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}

.dl-button {
  border: 1px solid #ccc5c5;
  padding: 5px;
  display: flex;
}

.dl-button2 {
  border: 1px solid var(--background-primary-color);
  padding: 5px;
  display: flex;
}

/* pinterest layout design */

.pinterest-card span {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
  padding: 0px 15px;
}

#columns {
  column-width: 280px;
  column-gap: 15px;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 0;
}

div#columns div {
  background-color: var(--background-secondary-color);
  border-radius: 16px;
  margin-bottom: 15px;
}

div.show-pointer div:hover {
  cursor: pointer;
}

.pinterest-card {
  position: relative;
}

div#columns figure {
  margin: 0 2px 15px;
  transition: opacity 0.4s ease-in-out;
  border-radius: 16px;
  display: inline-block;
  width: 99%;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

div#columns figure img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

div#columns figure p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 15px;
}

div#columns small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div#columns small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}

/* div#columns:hover figure:not(:hover) {
  opacity: 0.4;
} */

@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }

  #columns figure {
    width: 100%;
  }
}

/* pinterest end */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}

.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}

.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

.quotes-heading {
  line-height: 1.235;
  margin-bottom: 25px;
}

.container h2,
.event-title h6 {
  color: var(--portal-theme-primary);
}

.dashboard-heading {
  color: var(--portal-theme-primary);
  margin: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  margin-bottom: 40px;
}

.add-button {
  text-align: right;
  padding-top: 0.25rem;
}

.anchor-style {
  text-decoration: none;
  color: var(--portal-theme-primary);
}

.drawer-section {
  max-width: 400px;
}

.MuiDrawer-paperAnchorRight {
  width: 400px !important;
}

.drawer-padding {
  padding: 0 !important;
}

.drawer-cross-btn {
  margin-top: 12px;
  padding: 6px;
  margin-bottom: 0px !important;
}

.drawer-cross-btn svg {
  font-size: 16px;
}

.custom-video-player iframe {
  height: 100% !important;
}

.react-audio-player {
  width: 100%;
}

button.disabled:hover {
  cursor: not-allowed;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string p {
  margin-bottom: 0 !important;
}

.download-icon {
  text-align: end;
  margin-bottom: 20px;
}

.download-icon .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 40px;
  right: 10px;
}

.download-icon span {
  height: 20px !important;
  width: 20px !important;
}

.section-button button {
  /* display: block; */
  background-color: #145b6c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}

.dot-btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.set-max-height {
  max-height: 200px !important;
}

.goal-statement-messages .tab {
  margin-top: 10px;
}

.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

/* .event-title {
  background-color: var(--sidebars-background-color);
} */
.SnackbarItem-message {
  padding-right: 40px !important;
}

.SnackbarItem-action svg,
.SnackbarItem-message svg {
  color: var(--input-text-color) !important;
}

swd-pin-field {
  display: flex;
  justify-content: space-between;
}

.responce-messages .set-title-width {
  width: 57% !important;
}

.forms-drawer .simplebar-wrapper {
  height: 100% !important;
}

.circle-image .set-title-width span {
  color: var(--text-primary-color);
}

.circle-image .MuiAvatar-root {
  width: 60px;
  height: 60px;
}

.css-15ez4hi-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.password-tooltip {
  border: 2px solid var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
}

.carousel-container a {
  text-decoration: none;
}

.simplebar-content .css-1gtfl7l {
  width: 100% !important;
  z-index: 1111111111;
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  box-shadow: none;
}

.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}

.recordings-card {
  background-color: var(--background-secondary-color);
  margin-bottom: 20px;
  padding: 20px;
}

audio::-webkit-media-controls-panel {
  width: 100% !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

.dashboard_description img {
  margin: 0px auto !important;
}

/* .dashboard_description p {
  color: var(--text-primary-color) !important;
} */

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.sidebar-drawer {
  background-color: var(--background-primary-color);
}

.date-picker button {
  background-color: red;
}

.goal_achieved_lower_text {
  display: none;
}

@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 576px) {
  .add-button {
    text-align: center;
    padding-top: 0;
    margin-bottom: 30px;
  }

  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }

  .lesson-card-image {
    width: 100%;
  }

  .side-image-mui-card-image {
    width: 100%;
  }

  .kims-reply-card .set-image-width {
    width: 23% !important;
  }

  .kims-reply-card .set-title-width {
    width: 57% !important;
  }

  .program-btn,
  .program-category-btn {
    text-align: center;
  }

  .goal_achieved_lower_text {
    display: block;
  }

  .goal_achieved_upper_text {
    display: none;
  }

  .dashboard-circle {
    height: 325px;
  }

  .css-qen1a1-MuiDataGrid-root {
    border-left: none !important;
    border-right: none !important;
    padding: 0 10px;
  }

  header img {
    width: 100% !important;
    height: auto !important;
  }

  .SnackbarItem-message {
    max-width: 100%;
  }

  .support-ticket-button-contained,
  .support-ticket-button-outlined {
    margin-top: 20px;
  }
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }

  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }

  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

/* Comunity */
.new-community-page .profile-cards {
  cursor: auto;
}

.new-community-page .banner-section img {
  border-radius: 7px;
}

.banner-bg-image {
  width: 100%;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
  /* max-width: 100px; */
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
  background-color: black;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  height: 40px;
  width: 40px;
  border: 1px solid #ffff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
}

.score-row {
  font-size: 12px;
}

.score-row img {
  width: 16px;
  display: inline;
  margin-left: 5px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  min-width: 70%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  padding: 3px 5px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
  cursor: pointer;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 25px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: 5%;
  bottom: -10px;
}

.board-inner-image-box .rounded-image {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 5px;
  margin: auto;
  text-transform: uppercase;
}

.search-input input {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}
.textAreaAutoSize {
  width: 100%;
  border-radius: 8px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}

.search-input textarea {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
  padding-left: 15px;
}

/* .search-input input:focus {
  border: none;
  background-color: #262626;
} */

.favourite-buttton-box button,
.favourite-buttton button {
  background-color: #fff !important;
  border: none !important;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000 !important;
}

.favourite-buttton-box svg,
.favourite-buttton svg {
  color: var(--button-text-color);
  font-size: 20px;
  margin-top: -3px;
}

.favourite-videos-buttton-box button {
  background-color: #000;
  color: #fff;
  border: 1px solid var(--button-text-color) !important;
  font-weight: 500;
  white-space: nowrap;
}

.favourite-videos-buttton-box svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}

.input-with-profile img {
  border-radius: 50%;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #262626;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
  font-size: 14px;
}

.search-input ::placeholder {
  color: #fff;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
}

.likes-post:hover {
  cursor: pointer;
}

.likes-post .MuiAvatarGroup-avatar {
  width: 12px;
  height: 12px;
  font-size: 11px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.comment-icons {
  padding: 0px;
}

.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}

.comment-icons .MuiChip-root {
  border: none;
}

.comment-icons .MuiChip-root:hover {
  background-color: #262626;
}

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}

.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-community-page .img-back-border img {
  display: inline;
}

.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
  position: relative;
}

/* success-wrapper  */
.new-community-page .success-wrapper {
  padding-top: 40px;
}

.new-community-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}

.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}

.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}

.score-right-side h5 {
  margin-bottom: 0.8rem;
}

.main-section {
  position: relative;
}

.main-section hr {
  margin: 7px auto;
}

.leader-board-popup {
  position: relative;
}

.new-community-page .info-popup-box {
  position: absolute;
  left: -30px;
  top: -100px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 12px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
  outline: none;
  border: none;
}

.new-community-page .wealth-wallet span,
.new-community-page .wealth-wallet h5 {
  color: var(--button-outlined-color) !important;
  font-size: 11px;
}

.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

.new-community-page .MuiSlider-colorPrimary {
  margin-bottom: 5px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p {
  font-size: 10px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  height: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  z-index: 11111 !important;
}

.increase-scale .star-icon svg {
  color: var(--button-text-color);
}

.increase-scale .favourite-loading svg {
  font-size: 125px;
  left: 20px;
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}

.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #1d1c1d;
  font-size: 16px;
  border-radius: 50%;
}

.create-post-title-form .preview img {
  width: 100%;
  height: auto;
  max-height: 150px;
}

.post-creator {
  display: flex;
}

.post-creator .creator-name h3 {
  font-size: 13px;
}

.post-creator img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}

.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-theme-secondary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  fill: var(--input-text-color);
}

.video-image-selection .selected svg {
  color: var(--button-text-color);
  fill: var(--button-text-color);
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-secondary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.post-submit-btn {
  /* background-color: #fff; */
  /* color: #000; */
  /* border: none !important; */
  padding: 5px 30px;
}

.show-all-likes-tabs img {
  width: 16px;
}

.show-all-likes-box {
  overflow: hidden;
}

.show-all-likes .post-creator .creator-name h3 {
  color: #fff;
  text-align: start;
  margin-bottom: 0 !important;
}

.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}

.favourite-videos-list,
.feed-image,
.post-description {
  cursor: pointer;
}

.feed-image img {
  width: 100%;
}

.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
}

.post-description a {
  color: var(--button-text-color);
  text-decoration: none;
  z-index: 1;
}

.post-description span {
  z-index: 111;
  color: var(--button-text-color);
}

.favourite-videos-list h3 {
  color: var(--portal-theme-primary);
}

.liking-post img {
  width: 20px !important;
  font-size: 20px;
}

.poster-logo img {
  width: 45px;
}

.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 28px;
  height: 28px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}

.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}

.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}

.post-detail-box {
  position: relative;
}

.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #000000c2;
}

.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  overflow-y: scroll;
}

.feed-detail-popup .profile-cards {
  height: 100vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}

.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}

.gained-cores h2 {
  color: var(--portal-theme-primary) !important;
}

@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  .poster-name .card-title {
    font-size: 16px;
  }

  .poster-name .date-color {
    font-size: 14px !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
}

@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .btn-wrapper button {
    font-size: 13px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}

/* community css */
.hz-scroll {
  /* background-color: #1d1c1d; */
  background: linear-gradient(#1d1c1d 68%, black 32%);
  overflow: auto;
  white-space: nowrap;
  border-radius: 10px;
}

.hz-scroll .hz-scroll-div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 200px;
}

.hz-scroll .hz-scroll-left {
  position: absolute;
  top: 70px;
  left: -5px;
}

.hz-scroll .hz-scroll-right {
  position: absolute;
  top: 70px;
  right: -5px;
}

.hz-scroll .hz-scroll-icon {
  color: #000;
  background-color: #b88d38;
  border-radius: 50%;
  font-weight: 800;
  padding: 2px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
}

.hz-scroll .hz-scroll-icon:hover {
  background-color: #f6bd4b;
}

.hz-scroll .gb-left {
  border-left: solid 2px #e4b047;
}

.hz-scroll::-webkit-scrollbar {
  display: none;
}

.img-border {
  margin-top: -92px;
}

.img-border img {
  height: 110px;
}

.hz-scroll-title-odd {
  position: relative;
  top: -5px;
  z-index: 30;
}

.hz-scroll-title-even {
  position: relative;
  top: -5px;
  z-index: 30;
  right: 100px;
}

/* customize MUI slider label */
.coin-slider .MuiSlider-valueLabel {
  background-color: #fff;
}

.coin-slider-label {
  color: black !important;
}

.count-number-text {
  color: white !important;
}

.load-more-text {
  cursor: pointer;
}

.coin-icon {
  width: 22px;
}

.whole-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -40px -10px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.notification-popover-loader {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.score-row .activity-title {
  font-size: 14px !important;
  font-weight: 500;
}

.activity-win {
  font-size: 14px !important;
  font-weight: 500;
}

.comment-icons img {
  width: 16px;
  height: 16px;
}

.force-disabled {
  pointer-events: none;
  opacity: 0.4;
  display: none;
}

.live-comments {
  position: relative;
}

.bottom-add-comment {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--background-secondary-color);
  z-index: 10;
}

/* custom scrollbar start style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* custom scrollbar end style*/

.live-comments-list {
  height: 400px;
  overflow-y: scroll;
}

.new-chat-chip {
  position: absolute;
  bottom: 150px;
  font-size: 10px;
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
  padding: 5px 10px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

.click-able-link {
  text-decoration: none;
  color: var(--portal-theme-primary);
}

.pinned-comment {
  padding: 10px;
  border-bottom: solid 1px;
  border-color: var(--portal-theme-primary);
  position: absolute !important;
  width: 100%;
  background: var(--sidebars-background-color);
  z-index: 999;
}

.pinned-icon {
  color: var(--portal-theme-primary);
}

.inception .date-color {
  font-size: 9px !important;
  font-weight: 400 !important;
  padding-top: 5px;
}

.inception .affirmation-dots .remove-border {
  top: 2px;
  right: 0px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}

.inception .post-description {
  margin-bottom: 4px !important;
}

.inception .post-description span p {
  font-size: 12px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.inception p {
  font-size: 12px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.inception .post-description span {
  font-size: 12px !important;
  font-weight: 400;
  color: var(--button-text-color);
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.show-btn-disabled {
  color: #866a31;
  border-color: #866a31;
}

.goal_statement_yes_icons img {
  height: 25px;
}

.single-comment-card {
  background-color: #282728;
  padding: 10px;
  border-radius: 3px;
}

.single-comment-card .post-description {
  margin-bottom: 0;
}

/* .single-comment-card-dots .remove-border {
  top: 0 !important;
} */

.single-comment-card .card-title {
  font-size: 14px;
}

.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}

.liked-comment {
  color: var(--portal-theme-primary) !important;
}

.like-reply-box span {
  color: #dcdada;
}

.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 3px;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.pods-description p {
  margin-bottom: 0;
}

.notification-icons-btn {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.notification-popover-paper {
  top: 5px !important;
}

.notification-popover {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.notification-title {
  color: var(--portal-theme-primary);
}

.notification-item,
.notification-item:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}

.notification-item-unread,
.notification-item-unread:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
}

.notification-item-unread span {
  color: #fff;
}

.load-more-notification-btn button {
  margin: 10px auto;
  padding: 4px 8px;
}

.tiny-like-icon-notification img {
  width: 16px !important;
}

.board-main-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.goal_statement_sidebar {
  height: 15px;
  margin-left: 40px;
  color: #31a24c;
  fill: #31a24c;
}

.chat-image-preview img {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.comment-view-attachment {
  color: var(--portal-theme-primary);
  font-size: 11px;
  text-decoration: none;
  margin-left: 10px;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.is-sticky {
  position: fixed;
  top: 64px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

/* .community-left-sidebar {
  height: calc(100vh - 65px);
  overflow: auto;
} */
.goal-statement-completed-text p {
  color: var(--button-text-color);
  font-style: italic;
  text-align: end;
}

.lesson_detailed_description a {
  color: var(--portal-theme-primary);
}

.vault-filter-button {
  background-color: rgba(145, 158, 171, 0.08);
  padding: 6px 20px;
}

.download-pdf-button {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 5px;
  background-color: var(--button-background-color);
  font-style: normal;
}

.download-pdf-button-box {
  text-align: end;
}

@media (max-width: 540px) {
  .goal-statement-completed-text {
    text-align: center;
  }

  .goal-statement-completed-text p {
    text-align: center;
  }

  .download-pdf-button-box {
    text-align: center;
  }
}

.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}

.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--input-text-color);
  width: 100%;
}

.navbar-icon {
  color: var(--button-text-color);
  margin-right: 16px;
}

.dd-shop-cards .mui-custom-card-description {
  font-size: 24px;
}

.shop-detail-price {
  font-size: 30px;
}

.add-to-cart-button svg {
  margin-right: 10px;
  font-size: 20px;
}

.shop-modal-box .item-title {
  display: flex;
  justify-content: space-between;
}

.shop-modal-box .item-title span {
  width: 30px;
  height: 30px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  font-size: 19px;
  padding-top: 0px;
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.shop-modal-box .item-image img {
  width: 60px;
  border-radius: 10px;
}

.shop-modal-box .single-cross-button span {
  background-color: var(--portal-theme-secondary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  color: var(--portal-theme-primary);
}

.set-input-padding {
  padding: 16.5px 14px;
}

.shop-modal-box .subtotal span {
  font-size: 20px;
  margin: 0 5px;
}

.shop-modal-box .item-quantity,
.detail-item-quantity .item-quantity {
  display: flex;
  border: 1px solid var(--portal-theme-secondary);
  justify-content: space-between;
  padding: 0 7px;
  font-size: 20px;
  max-width: 115px;
}

.detail-item-quantity .item-quantity {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
}

.shop-modal-box .item-quantity input,
.detail-item-quantity .item-quantity input {
  width: 60px;
  background-color: transparent;
  outline: none;
  border-left: 1px solid var(--portal-theme-secondary);
  border-right: 1px solid var(--portal-theme-secondary);
  border-bottom: 0;
  color: var(--input-text-color);
  font-size: 16px;
  text-align: center;
}

.detail-item-quantity .item-quantity input {
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary);
}

.shop-modal-box .item-quantity span,
.detail-item-quantity .item-quantity span {
  cursor: pointer;
}

.complete-Status {
  color: #28a745;
}

.shop-item-checkout .item-image {
  position: relative;
}

.shop-item-checkout .items-quantity {
  background-color: var(--portal-theme-primary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  position: absolute;
  top: -5px;
  right: 0;
}

.shop-item-checkout-bill {
  background-color: var(--sidebars-background-color);
  min-height: 90vh;
  padding: 50px;
}

.image-slider .image-thumbnails {
  display: flex;
  justify-content: space-between;
}

.image-slider .main-image img {
  max-height: 400px;
}

.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}

.shop-cards .mui-custom-card-content h3 {
  font-size: 0.8rem;
  color: #fff;
}

.shop-cards .mui-custom-card-content .mui-custom-card-description {
  font-size: 16px;
  color: var(--portal-theme-primary);
}

.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}

.off-percentage {
  display: flex;
}

.off-percentage p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.off-percentage .plus-icons {
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-primary);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 10px;
}

.shop-item-rating {
  display: flex;
}

.shop-item-rating p {
  margin-bottom: 0;
  margin-top: 3px;
  margin-left: 10px;
}

.shop-modal-box-cart .item-image img {
  width: auto;
}

.total-price h4 {
  color: var(--portal-theme-primary);
}

.time-zone-box input {
  color: var(--input-text-color);
}

.question-description-top-line {
  height: 4px;
  width: 25px;
  margin-bottom: 20px;
  border: 1px solid var(--portal-theme-primary);
  background-color: var(--portal-theme-primary);
}

.question-description-top-box p {
  font-size: 1rem;
}

/* question section  */

.top-banner-section {
  /* / background: #f6f7f9; /
  / background: black; / */
  /* background-image: url("/images/Questionpagebg.png") */
  background-position: center;
  background-size: cover;
}

.spaces {
  padding-top: 100px;
}

.answer {
  width: 100%;
  height: 20px;
}

.selectedAnswer {
  background-color: var(--portal-theme-secondary);
}

/* .question-answer:active {
  background-color: #B59D4E;
} */
.top-banner-section .right-part {
  /* / background-color: #fff; /
  / border: 1px #e1e5e7 solid; /
  / background-color: black; / */

  /* / border: 1px solid; /
  / border-radius: 10px; / */
  padding: 25px;
  z-index: 10;
}

.right-part h2 {
  color: white;
}

.right-part p {
  color: #b59d4e;
}

.questions-wrapper .question-card {
  border: 1px #b59d4e solid;

  /* / padding: 25px; / */
  z-index: 10;
  color: white;
  /* / background: #f6f7f9; / */
}

.questions-wrapper .question-card h6 {
  color: #b59d4e;
  border-bottom: 1px solid #ccb668;
  padding: 17px 21px;
  margin-bottom: 0px;
}

.faq-wrapper .faq-card {
  border: 1px #e1e5e7 solid;
  border-radius: 20px;
  padding: 25px;
  z-index: 10;
  background: #f6f7f9;
}

.faq-wrapper .faq-card p {
  font-size: 14px;
}

.assessment-question h6 {
  font-size: 1.2rem;
  letter-spacing: 1.3px;
  font-weight: 600;
}

.mediation-text h6 {
  font-size: 16px;
  color: #ccc9c4;
  font-family: "Inter", sans-serif;
}

.assessment-question .MuiFormControlLabel-label {
  font-family: "Montserrat";
  line-height: 1.4;
  font-weight: normal !important;
  font-size: 16px;
  letter-spacing: 1.3px;
}

.theme-radio {
  color: #cea254 !important;
}

.top-vedio-container {
  /* / padding-top: 145px; / */
  border-radius: 10px;
  /* background-image: url("/images/Rectangle.jpg"); */
  background-size: cover;
  background-position: center;
}

.inner-container {
  margin: 77px 5px 10px 2px;
  color: white;
  background: transparent;
}

.scale-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.description-container {
}

.scale-index {
  margin-top: 5px;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 500;
  border-top: solid 1px #cea25450;
  border-bottom: solid 1px #cea25450;
  cursor: pointer;
}

.scale-index:hover {
  background-color: #cea254;
}

.scale-border-left {
  border-left: solid 1px #cea25450;
}

.scale-border-right {
  border-right: solid 1px #cea25450;
}

.take-assessment-wrapper {
  min-height: calc(100vh - 110px);
}

.position-centered-rel {
  position: relative;
}

.position-centered-abs {
  position: absolute;
  /* top: 50%; / position the top  edge of the element at the middle of the parent /
  left: 50%; / position the left edge of the element at the middle of the parent / */
  transform: translate(-50%, -50%);
}

.btn-spacing {
  padding: 20px 20px;
  background: white;
  border: solid 5px #cea254;
  border-radius: 15px;
}

.assessment-text-area {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 4px;
  background-color: var(--sidebars-background-color);
  font-size: 16px;
  resize: none;
  color: white;
}

.black-btn {
  background-color: #ccb668;
  color: black;
  padding: 10px 55px;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}

.question-answer {
  /* border-bottom: 1px solid #ccb668; */
  padding: 11px;
}

.question-answer:hover {
  background-color: var(--portal-theme-secondary);
}

.color {
  background-color: #f6f7f9;
}

.selected-option {
  background-color: green;
}

/* .question-card .css-1nrlq1o-MuiFormControl-root{
  width: 100% !important;
} */
.MuiFormControl-root {
  width: 100% !important;
}

@media screen and (max-width: 780px) {
  .scale-index {
    margin: 5px;
    width: 65px;
    height: 65px;
    border: solid 1px white;
  }
}

@media screen and (max-width: 768px) {
  .position {
    left: 0px !important;
  }

  .spaces {
    padding-top: 75px;
  }
}

.attach-with-iframe {
  margin-right: -20px;
}

.qustion-section-summary p {
  color: var(--portal-theme-primary);
}

.top-vedio-container .wrapper .player iframe {
  position: absolute;
  top: 0px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(181, 157, 78);
}

/* question section end */
.home-page-plan ul {
  list-style-type: none;
  padding-left: 0;
}

.home-page-plan ul li {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 22px;
}

.home-page-plan ul .tick {
  background: url(../images/Vector-tick.png) no-repeat left 4px;
}

.home-page-plan ul .cross {
  background: url(../images/cross-plan-icon.png) no-repeat left 5px;
}

.habit-days ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
}

.habit-days ul li {
  display: inline-block;
  margin-right: 20px;
  width: 25px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
  background-color: var(--portal-theme-secondary);
  border-radius: 3px;
}

.habits-slider .MuiSlider-markLabel {
  left: 97%;
  font-size: 16px;
}

.habits-slider .MuiSlider-valueLabel {
  font-size: 16px;
}

.silder-value {
  padding-top: 5px;
  margin-right: 10px;
}

.habits-gray-description {
  color: var(--text-secondary-color);
  font-weight: normal;
}

.frequency-labels .MuiCheckbox-colorPrimary {
  padding: 5px;
}

.frequency,
.habit-reminder {
  padding-left: 10px;
}

.frequency p,
.habit-reminder p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

/* .habits-box {
  position: relative;
} */

.habits-box .remove-border {
  position: absolute;
  top: 10px;
  right: 5px;
}

.habit-day-box {
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  border-radius: 10px;
  font-size: 15px;
  text-transform: none;
}

.habit-day-box span {
  font-size: 16px;
  letter-spacing: 2px;
}

.habit-detail .detail-image {
  width: 150px;
  margin: 20px auto;
}

.habit-detail .detail-image img {
  border-radius: 10px;
}

.habit-name span {
  /* color: var(--button-text-color) */
  color: var(--text-primary-color);
}

.habit-name p {
  color: var(--text-secondary-color);
}

.habit-name .detail-frequency ul {
  padding-left: 0;
  padding-top: 8px;
}

.habit-name .detail-frequency ul li {
  background-color: var(--portal-theme-secondary);
  list-style-type: none;
  display: inline-block;
  width: 30px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  border-radius: 3px;
}

.change-weeks {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.change-weeks-bg {
  background-color: var(--portal-theme-secondary);
  padding: 5px;
  width: 100%;
  text-align: center;
  height: 35px;
  border-radius: 3px;
}

.change-weeks .arrow-icon {
  width: 10%;
}

.change-weeks .arrow-icon svg {
  font-size: 14px;
}

.change-weeks .week-text {
  width: 75%;
}

.change-weeks .week-text .change-weeks-bg {
  padding-top: 5px;
}

.habit-detail .weeks-days ul,
.detail-frequency ul {
  display: flex;
  justify-content: space-between;
}

.habit-detail .weeks-days ul li {
  height: 60px;
  width: 35px;
  font-size: 17px;
  margin-right: 0;
  padding-top: 5px;
}

.habit-detail .weeks-days ul li p {
  color: var(--text-primary-color);
}

.habit-detail .weeks-days ul li svg {
  fill: var(--button-text-color);
  font-size: 20px;
}

.habit-detail .card {
  background-color: var(--portal-theme-secondary) !important;
}

.habit-detail .notes-input {
  padding: 10px;
  padding-right: 20px;
}

.habit-detail .notes-input .normal-text {
  text-align: left;
  color: var(--text-primary-color);
}

.habit-detail .notes-input .date-color {
  text-align: right;
  margin-top: 16px;
}

.habit-detail .notes p {
  color: var(--text-primary-color);
  margin-bottom: 10px;
}

.habit-detail .notes a {
  color: var(--portal-theme-primary);
}

.popup-detail-box {
  height: 100vh;
  overflow: hidden;
}

.detail-page-box {
  height: 90vh;
  overflow: auto;
}
.MuiOutlinedInput-input {
  color: var(--input-text-color) !important;
}

.detail-page-box .profile-main-comments {
  height: 210px;
  overflow: auto;
}

.popup-detail-box .profile-main-comments {
  height: 315px;
  overflow: auto;
}

#jitsi-meet-box {
  height: 100% !important;
  position: relative;
}

.jitsi-control {
  color: #fff;
  position: absolute;
  border: none;
  bottom: 20px;
  padding: 8px;
  border-radius: 3px;
}

.button-handler .control-call-end {
  background-color: red;
  left: 20px;
}

.button-handler .control-audio {
  background-color: var(--portal-theme-primary);
  left: 45%;
}

.button-handler .control-video {
  background-color: var(--portal-theme-primary);
  right: 20px;
}

/* assessment-wrapper Page css */
.assessment-wrapper h1,
.assessment-wrapper h2 {
  font-size: 30px;
  color: #ffffff;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0;
}

.assessment-wrapper h2 {
  font-size: 24px;
  color: #ffffff;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

.vision-div h4 {
  color: #ccc9c4;
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 0px;
}

.assessment-wrapper iframe {
  border-radius: 15px;
  max-width: 100%;
}

.top-section-video iframe {
  border-radius: 15px;
  max-width: 100%;
}

.assessment-wrapper-description h2 {
  color: #b59d4e;
  font-size: 30px;
}

.assessment-wrapper label {
  color: #ffffff;
  font-size: 12px;
}

.label-text h4 {
  font-size: 18px;
  color: #ccc9c4;
  font-weight: 600;
  margin-bottom: 16px;
}

.text-white-label h4 {
  color: #fff;
  font-size: 16px !important;
}

.label-text h3 {
  font-size: 20px;
  color: #fff !important;
  margin-bottom: 20px !important;
}

.label-text p {
  color: #ffffff;
}

.assessment-wrapper .pt-70 {
  padding-top: 70px;
}

.assessment-wrapper .pt-120 {
  padding-top: 120px;
}

.assetssment-para h3 {
  font-size: 24px;
  color: #ccc9c4;
  font-family: "Inter", sans-serif;
  margin-bottom: 16px !important;
}

.assetssment-para p {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9c4;
}

.diary-para p {
  font-size: 16px;
  font-weight: 400;
  color: #ccc9c4;
  margin-top: 20px;
}

.assetssment-tab-para h4 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #ffffff;
}

.assetssment-tab-para p {
  font-size: 14px !important;
  /* color: #ccc9c4; */
}

.assesment-list li {
  background: #141717;
  border: 1px solid rgba(71, 66, 34, 0.3);
  padding: 0px 14px;
  padding-bottom: 14px;
  border-radius: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #ccc9c4;
  margin-top: 16px;
}

.assesment-list li > span {
  padding-right: 20px;
  margin-top: 14px;
}

.assesment-list li .FaStar-button {
  margin-top: 14px;
}

.star {
  display: inline;
  cursor: pointer;
}

.star > button {
  color: #b59d4e;
  background: transparent;
  border: 1.5px solid #b59d4e;
  border-radius: 50%;
  margin: auto 3px;
  padding: 3px 5px 15px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
}

.star > button p {
  font-size: 14px;
  font-weight: 600;
}

.assessment-btn button {
  background: #b59d4e;
  color: #000000;
  padding: 8px 40px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  border: none !important;
  outline: none !important;
}

.assessment-btn-tab button {
  background: #b59d4e;
  color: #000000;
  padding: 12px 25px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  outline: none;
  border: none;
}

.btn-active {
  background: #b59d4e !important;
  color: #141717 !important;
  font-weight: 600 !important;
  font-size: 14px;
  border-radius: 50px;
}

.assesment-tab-btn {
  background: #141717;
  color: #ccc9c4;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 30px;
  font-weight: 500;
  border: none !important;
  outline: none !important;
}

@media (max-width: 912px) {
  .assessment-wrapper iframe {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 525px) {
  .assessment-wrapper iframe {
    max-width: 100%;
    height: auto;
  }

  .assesment-tab-btn {
    padding: 8px 20px;
  }

  .assessment-wrapper h1 {
    font-size: 20px;
  }
}

.result-div {
  background: #202220;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock-box {
  background: linear-gradient(0deg, #b59d4e 50%, #d4b85b 0);
  border-radius: 8px;
  padding: 25px 25px 20px 15px;
}

.dots-timer {
  font-size: 24px;
}

.text-para {
  color: #ccc9c4;
  font-size: 15px;
  margin-bottom: 0px !important;
  padding-top: 8px !important;
  font-weight: 400;
}

.clock-box p {
  color: #000608;
  font-size: 34px;
  margin-bottom: 0px !important;
}

.result-div h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  padding-top: 5px;
}

.result-div p {
  font-size: 4px;
  font-weight: 600;
  margin-bottom: 0 !important;
  color: #ffffff;
}

.bg-color-div {
  background: #b59d4e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-color-div i {
  font-size: 28px;
  color: #000608;
}

.assessment-result-box {
  padding: 15px 20px;
}

.btn-yellow {
  background: #f5f17f;
}

.btn-yellow h4 {
  color: #000609 !important;
}

.assessment-result-box h4 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 16px;
}

.assessment-result-box p {
  font-size: 12px;
  /* color: #000609; */
  font-weight: 400;
  margin-bottom: 0;
}

.border-div-assessment {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #1a1c17) !important;
  border: 1px solid rgba(181, 157, 78, 0.81);
  padding: 30px 0;
  border-radius: 8px;
}

.border-div-assessment h6 {
  color: #b59d4e;
  font-weight: 400;
  font-size: 14px;
}

.border-div-assessment img {
  display: block;
  margin: 0 auto;
}

.z-index-10 {
  z-index: 10;
}

.borderbg-top {
  border-top: 1px solid rgba(181, 157, 78, 0.81);
  padding: 20px 0;
}

.event-locotion-container {
  padding: 22px 25px 0px;
}

.time-count-container {
  border: 1px solid #b59d4e;
  padding-bottom: 3px;
  border-radius: 5px;
  border-top: none;
  position: relative;
}

.timer-count {
  text-align: center;
  font-size: 28px;
  color: #000;
  font-weight: 600;
}

.timer-count .count {
  width: 55px;
  padding: 20px 0px;
  border-radius: 5px;
  background: linear-gradient(#d4b85b 50%, #b59d4e 50%);
}

.dashboard-notification-timer .timer-count {
  justify-content: space-between;
}

.dashboard-notification-timer .timer-count .count {
  width: 40px;
  padding: 14px 0px;
  font-size: 20px;
}

.dashboard-notification-timer .time-count-container {
  height: 62px;
}

.dashboard-notification-timer h2 {
  color: #fff;
  text-align: center;
}

.timer-count .colon {
  padding-top: 17px;
  color: rgba(204, 201, 196, 0.9);
  font-size: 35px;
}

.dashboard-notification-timer .timer-count .colon {
  font-size: 20px;
}

.dashboard-program-description p {
  text-align: justify;
}

@media (max-width: 912px) {
  .timer-count .count {
    width: 40px !important;
  }
  .dashboard-notification-timer .timer-count .count {
    width: 32px !important;
    padding: 10px 0px;
  }
  .dashboard-notification-timer .time-count-container {
    height: 54px;
  }
}

.time-detail {
  width: 100%;
  text-align: center;
  color: #ccc9c4;
  font-size: 16px;
}

.time-container-site {
  padding: 14px 10px 0px;
}

@media (max-width: 1024px) {
  .timer-count .count {
    width: 59px;
    padding: 16px 0px;
    background-color: #b59d4e;
    border-radius: 5px;
  }
}

.visioneer-btn a {
  background: #b59d4e;
  color: #000608;
  font-size: 14px;
  padding: 8px 35px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
}

.check-box-div {
  background: #202220;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.check-box-lable label {
  font-size: 12px;
  color: #ccc9c4;
  font-weight: 500;
}

.w-check-50 {
  width: 65px;
}

#inputOne + label {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
}

#inputTwo + label {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
}

.css-xzqsgo {
  display: none !important;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: block;
  border: solid 5px #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px #fff;
  cursor: pointer;
  margin: 10px;
  transition: box-shadow 0.4s, border 0.4s;
  height: 35px;
  width: 35px;
}

input[type="checkbox"] + label:hover,
input[type="checkbox"]:checked + label {
  border: 5px solid #b59d4e;
  box-shadow: 0 0 1px #b59d4e;
}

input[type="checkbox"]:checked + label::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 28px;
  color: #99b83c;
  content: "\2714";
  line-height: 1;
  margin: auto;
  text-align: center;
  height: 1em;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  color: white;
  font-family: "Montserrat";
  background-color: #141717;
  border: 1px solid #474222;
  border-radius: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.upload-div {
  background: #141717;
  /* height: 200px; */
  width: 100%;
  margin: 20px 0;
}

.upload-div img {
  object-fit: cover;
  border: 0;
  height: 210px !important;
  width: 100% !important;
}

.upload p {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
  font-size: 38px;
  transition: opacity 0.25s ease-in-out;
}

.text-white-label label {
  color: #ffffff;
}

.rang-slider-div .range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  background: #b59d4e;
  transform: translate(0, -50%);
  border-radius: 30px;
  width: 100%;
  height: 21px;
}

.rang-slider-div .range-slider .range-slider__thumb {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50px;
  background: #000609;
  display: flex;
  justify-content: start;
  align-items: start;
  border: 3px solid #202220;
}

.rang-slider-div .range-slider .range-slider__thumb[data-disabled] {
  display: none;
  width: 0;
  height: 0;
}

.rang-slider-div .range-slider {
  width: 100%;
  height: 20px;
  background: #ffffff;
  outline: none;
  border-radius: 30px;
  opacity: 1;
  transition: opacity 0.2s;
}

.upload.is-highlight {
  background-color: #141717;
  border: 1px solid #474222 !important;
  border-radius: 8px;
}

.upload.is-highlight p {
  opacity: 1;
}

.upload.is-drop p {
  opacity: 1;
}

.upload-button-btn {
  position: absolute;
  bottom: 0;
  right: 12px;
}

.upload-file {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.button-upload-image {
  border-radius: 30px;
  padding: 6px 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #b59d4e;
  color: #0c0f0c;
  border: 0;
  font-weight: 600;
}

.assessment_filler_image .top-image {
  margin-left: -1px !important;
}

.coins-div h3 {
  margin-bottom: 0 !important;
}

.coins-div img {
  margin: 0 auto;
}

.coins-div p {
  font-size: 10px;
  white-space: nowrap;
}

.daily-message-card {
  background-color: var(--sidebars-background-color);
  padding: 10px;
  border-radius: 5px;
}

.message-share-button button {
  padding: 3px 8px;
  background-color: #fff !important;
  color: #000 !important;
  font-size: 11px;
  font-weight: 500;
  border: none !important;
  border-radius: 2px !important;
}

.message-share-button a {
  text-decoration: none !important;
}

.daily-message-card .message-title p {
  font-size: 12px;
  margin-bottom: 0;
}

.daily-message-card .message-title span {
  font-size: 10px;
}

.daily-message-card audio {
  height: 40px;
}

.visioneering-accepted {
  color: rgb(48 159 37);
}

.visioneering-complete-button button {
  background-color: var(--portal-theme-primary);
  color: #fff;
  padding: 3px 8px;
  border: none;
  border-radius: 3px;
}

.static-date-picker {
  width: 100%;
  background-color: transparent;
  /* padding-left: 3rem; */
}

.static-date-picker .MuiPickerStaticWrapper-content {
  background-color: transparent;
}

.static-date-picker .css-epd502,
.static-date-picker .MuiCalendarPicker-root,
.static-date-picker .MuiDayPicker-header {
  width: 100%;
}

.static-date-picker .MuiDayPicker-header,
.static-date-picker .MuiDayPicker-weekContainer {
  justify-content: space-between;
}

.static-date-picker .MuiPickersCalendarHeader-root {
  padding-left: 16px !important;
  padding-right: 7px;
}

/* .static-date-picker .MuiDayPicker-header {
  padding-left: 15px;
} */

.static-date-picker .MuiPickersDay-root,
.static-date-picker .MuiDayPicker-weekDayLabel {
  font-size: 1rem !important;
}

.visioneer-btn svg {
  height: 20px;
}

@media (max-width: 912px) {
  .static-date-picker {
    padding-left: 0;
    margin-top: 25px;
  }
}

.visioneering-list-description {
  cursor: pointer;
}

.visioneering-list-description h3 {
  color: #fff;
}

.visioneering-list-description p {
  font-weight: 400;
}

.visioneering-textarea label {
  font-size: 14px;
}

.mui-slider .MuiSlider-thumb {
  color: black;
  border: 1px solid var(--portal-theme-primary);
}

.mui-slider .MuiSlider-markLabel {
  color: #fff !important;
}

.mui-slider .MuiSlider-markLabel[data-index="0"] {
  left: 17px !important;
  right: auto;
}

.mui-slider .MuiSlider-markLabel[data-index="1"] {
  left: auto !important;
  right: -23px;
}

.title-with-back-button {
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}

.MarkComplete {
  background: transparent;
  border: 1px solid #b59d4e;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.mark-complete-check-box-lable label {
  font-size: 12px;
  color: #ccc9c4;
  font-weight: 500;
  display: inline-block !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
}

.mark-complete-check-box-lable input[type="checkbox"]:checked + label::after {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 20px;
  color: #000;
  content: "\2714";
  line-height: 1;
  margin: auto;
  text-align: center;
  height: 1em;
}

.challenge-date span,
.challenge-day-in-tatal span {
  color: var(--button-text-color) !important;
}

.mark-complete-check-box-lable button {
  background: #b59d4e;
  color: #000608;
  font-size: 14px;
  padding: 8px 35px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  border: none;
  outline: none;
}

.countdown-timer h4 {
  color: #fff;
  font-size: 15px;
  margin-bottom: 0;
}

.attitude-assessment-not-found {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}

.textarea-autosize {
  outline: none;
}

.text-spacing-gratitude-daily {
  line-height: 3;
  font-size: 14px !important;
}

.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}

.user-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 1px solid var(--portal-theme-secondary);
}

.user-search-input:focus {
  outline: none;
}

.user-search-box {
  position: relative;
}

.pinned-image {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.mui-slider .MuiSlider-valueLabelOpen {
  background-color: var(--sidebars-background-color);
  padding: 4px 10px;
}

.show-comment-names {
  background-color: #333232;
  color: #fff;
  border-radius: 3px;
}

.show-comment-names-box {
  position: relative;
}

.show-comment-names {
  position: absolute;
  left: 0;
}

.likes-model-tabs {
  padding: 10px;
}

.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}

.feed-activity-box {
  max-height: 350px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed-activity-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed-activity-box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.user-info-card .date-color {
  font-size: 12px !important;
}

.leading-section span {
  font-size: 16px !important;
  color: #c2a366 !important;
  font-weight: bold;
}

.leading-section button {
  padding-top: 17px;
}

.leading-section .slick-prev {
  left: -34px;
  top: 75px;
}

.leading-section .slick-next {
  right: -34px;
  top: 70px;
}

.leading-section .image-box {
  height: 45px;
  /* width: 45px; */
  /* border: 1px solid #fff; */
  border-radius: 50%;
  text-align: center;
  /* background: #000; */
  padding-top: 25px;
  /* position: absolute; */
  top: 4px;
  text-align: right;
}

.leading-section .image-box-border {
  height: 45px;
  width: 45px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  text-align: center;
  background: #000;
  padding-top: 7px;
  position: absolute;
  top: 4px;
}

.leading-section .image-box img {
  height: auto;
  width: 40px;
  display: inline-block;
}

.image-coin-box,
.road-map-item {
  position: relative;
}

.leading-section .slick-slide > div {
  margin: 0;
}

.road-map-item {
  background-image: linear-gradient(180deg, #181308, #000);
  /* padding-top: 32px !important; */
  text-align: right;
}

.road-map-item span {
  margin-top: 15px;
}

.road-map-item .road-map-title {
  position: absolute;
  top: -51px;
}

.road-map-item .road-map-icon svg {
  font-size: 50px;
  color: #c2a366;
  fill: #c2a366;
}

.road-map-item .road-map-icon p {
  white-space: nowrap;
  margin-bottom: 0;
}

.road-map-item .road-map-icon {
  text-align: center;
  position: absolute;
  top: -60px;
  margin-left: 0 !important;
  z-index: 111111;
}

.road-map-item .road-space {
  left: 14% !important;
}

.leading-section .slick-track {
  padding-top: 40px;
  padding-bottom: 30px;
}

/* .road-map-active-item .image-box {
  border: 1px solid #c2a366;
  padding-top: 8px !important;
} */

.road-map-coins-count {
  font-weight: 600;
  font-size: 15px;
}

.dotted-lines {
  border-bottom: 3px dotted #fff !important;
  /* padding-top: 11px; */
}

.dotted-lines-colored {
  border-bottom: 3px dotted #c2a366 !important;
  /* padding-top: 11px; */
}

.waveButton {
  background: OrangeRed;
  color: white;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  outline: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.controls {
  text-align: left;
  padding: 1em;
}

.waveAudio {
  height: 200px;
}

.rangeVolume {
  color: var(--portal-theme-primary);
}

.visioneering-box {
  position: relative;
}

.view-challenge-button-box {
  position: absolute;
  left: 12px;
  bottom: 0;
}

.leader-board-popup {
  top: 20px !important;
  left: -20px !important;
}

.legend-title .color-box {
  height: 14px;
  width: 10%;
  margin-top: 3px;
  margin-right: 15px;
}

.legend-title .description-box {
  width: 90%;
  /* font-size: 15px; */
}

.btn-btn-portal-navigate {
  cursor: pointer;
}

.ninty-day-slider {
  width: 90%;
}

.full-open-calender .MuiCalendarPicker-root {
  background-color: transparent !important;
}

.notifications-circle {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 16px;
}

.leading-section .before-slider-empty-div {
  position: absolute;
  width: 43px;
  height: 90px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.leading-section .after-slider-empty-div {
  z-index: -1;
  position: absolute;
  width: 57px;
  right: 0;
  top: 0;
  height: 90px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.leading-section .empty-right-dev {
  position: absolute;
  width: 43px;
  height: 130px;
  background-image: linear-gradient(180deg, #181308, #000);
  margin-top: 40px;
}

.road-map-item-last {
  margin-right: 60px;
}
.road-map-item-first {
  margin-left: 53px;
}

@media (max-width: 912px) {
  .road-map-item-last {
    margin-right: 73px;
  }
  .leading-section .after-slider-empty-div {
    width: 73px;
  }
}

.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}

.dynamite-level-image {
  height: 30px !important;
  width: 34px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
}

.community-level select {
  color: #fff;
  padding: 2px 4px;
  background-color: transparent;
  border: 1px solid var(--button-text-color);
  outline: none;
  border-radius: 3px;
}

.community-level select option {
  background-color: var(--sidebars-background-color) !important;
}

.dashboard-card {
  box-shadow: none;
  /* text-align: center; */
  color: #fff;
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding-top: 24px;
  /* padding: 24px; */
}

.dashboard-card .title {
  color: var(--portal-theme-primary);
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0 !important;
}
.dashboard-card .text-section {
  margin-top: 24px;
}
.dashboard-card .main-box {
  padding-right: 24px;
  padding-left: 24px;
}

.dashboard-card .icon-wrapper-style {
  margin: auto;
  display: flex;
  border-radius: 50%;
  align-items: center;
  padding: 24px;
  margin: auto auto 24px;
  width: 64px;
  height: 64px;
  justify-content: center;
  background-image: linear-gradient(
    135deg,
    rgba(34, 154, 22, 0) 0%,
    var(--portal-theme-secondary) 100%
  );
}

.dashboard-card h3 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.dashboard-card .date-color {
  font-weight: normal !important;
  font-size: 15px !important;
}

.dashboard-notification-card {
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  padding: 14px 10px;
}

.dashboard-notification-card .warning-icon {
  width: 30px;
  height: 30px;
}
.dashboard-notification-card h3 {
  font-weight: 500;
  font-size: 15px;
}

.dashboard-notification-card p {
  font-size: 16px;
  color: var(--text-secondary-color);
}

.dashboard-notification-card a,
.dashboard-notification-card button {
  border: 1px solid var(--portal-theme-primary);
  background-color: transparent;
  outline: none;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  padding: 6px 8px;
  text-decoration: none;
}

.dashboard-notification-card a {
  display: inline-block;
}

.dashboard-chart .title {
  color: #fff;
}

.notification-button button {
  border: 1px solid var(--portal-theme-primary);
  background-color: transparent;
  outline: none;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  padding: 3px 8px;
}

.dashboard-progress-icon {
  height: 80px !important;
  width: 80px !important;
}

.dashboard-progress-box {
  position: relative;
}

.dashboard-progress-text {
  top: 24px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.dashboard-progress-text-title {
  font-size: 20px;
}

.starting-image {
  width: 35px;
  height: auto;
  margin: 32px 5px 0 auto;
}

.end-slider-image {
  width: 35px;
  height: auto;
  margin: 32px auto 0 0;
}

.days-in-total-text {
  font-size: 15px;
}

.days-in-total-text span {
  color: var(--portal-theme-primary);
  font-weight: bold;
}
.dashboard-lessons-box {
  height: 300px;
  overflow-y: auto;
}

.source-session h3 {
  font-weight: normal;
  font-size: 15px;
}

.source-session p {
  font-size: 14px;
  color: var(--text-secondary-color);
}

.dashboard-card .timer-box {
  margin-top: 24px;
  background-color: var(--portal-theme-secondary);
  padding: 10px 24px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 16px;
}

.dashboard-card .timer-box p {
  margin-bottom: 0 !important;
}
.centerAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -26%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.centerAlign0 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 30%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.MuiClockPicker-root {
  background-color: var(--sidebars-background-color);
}

.MuiClockPicker-root span {
  color: #fff;
}

.MuiClockPicker-root .MuiClock-amButton:hover,
.MuiClockPicker-root .MuiClock-pmButton:hover {
  background-color: var(--button-text-color);
}

.reminder-time-form input {
  color: white;
}

.reminder-time-form .submit-button {
  padding: 0px 20px;
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.pagination-style button {
  background-color: var(--portal-theme-secondary);
  color: #fff;
}

.pagination-space-from-bottom p {
  margin-bottom: 0px !important;
  color: #fff;
}
.MuiTableSortLabel-root,
.MuiTableCell-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiSelect-select {
  color: white;
}
/* .MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: white !important ;
} */
.dashboard-program-timer-box {
  display: flex;
  align-self: center;
}

/* mui-bg-custom-table */
.mui-bg-custom-table {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.mui-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
  color: #fff;
}
.mui-bg-custom-table .MuiTablePagination-actions svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}
.MuiTableCell-root {
  color: #fff;
  background-color: var(--sidebars-background-color) !important;
}
.MuiToolbar-root input {
  color: #fff;
}

.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
.css-1esf93k-MuiButtonBase-root-MuiChip-root,
.css-11mo73z-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.selected-booking-detail span {
  display: block;
  color: var(--text-secondary-color);
  margin-bottom: 1rem;
}

.disabled-lesson {
  cursor: not-allowed;
}
.new-message-search-box {
  position: relative;
}

.new-message-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
.new-message-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 2px solid var(--portal-theme-secondary);
}

.new-message-search-input:focus {
  outline: none;
}

.message-user-avatar img {
  border-radius: 50%;
}

.message-user-box {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
  cursor: pointer;
}

.message-user-box p {
  margin-bottom: 0;
}
.css-1c1nuln {
  background-color: transparent !important;
  color: rgb(255, 255, 255) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
}
